<template>
  <div>
    <loader-component v-if="loading" />

    <div class="card" v-else>
      <div class="card-body">
        <div class="text-right">
          <b-button v-b-modal.modal variant="primary" class="mb-3"
            ><span class="pr-4"><i class="fas fa-user-plus"></i></span>Create
            New Cashier
          </b-button>
        </div>
        <b-modal
          id="modal"
          title="Create Cashier"
          @show="resetModal"
          @hidden="resetModal"
          @ok="handleSave"
          size="lg"
        >
          <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-form-group
              label-for="name-input"
              invalid-feedback="Name is required"
              :state="nameState"
            >
              <label for="">Name <span class="text-danger"> * </span></label>
              <b-form-input
                id="name-input"
                v-model="form.name"
                :state="nameState"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label-for="name-input"
              invalid-feedback="Email is required"
              :state="emailState"
            >
              <label for="">Email <span class="text-danger"> * </span></label>
              <b-form-input
                id="name-input"
                v-model="form.email"
                :state="emailState"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label-for="name-input"
              invalid-feedback="Phone number is required"
              :state="phone_numberState"
            >
              <label for=""
                >Phone number <span class="text-danger"> * </span></label
              >
              <b-form-input
                id="name-input"
                v-model="form.phone_number"
                :state="phone_numberState"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label-for="name-input"
              invalid-feedback="Password is required"
              :state="passwordState"
            >
              <label for=""
                >Password <span class="text-danger"> * </span></label
              >
              <b-form-input
                id="name-input"
                type="password"
                v-model="form.password"
                :state="passwordState"
                required
              ></b-form-input>
            </b-form-group>
          </form>
        </b-modal>
        <div>
          <div class="row justify-content-end">
            <div class="col-md-6">
              <b-form-group
                label-for="filter-input"
                label-cols-sm="3"
                label-align-sm="right"
                label-size="sm"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-input
                    id="filter-input"
                    v-model="filter"
                    type="search"
                    placeholder="Type to Search"
                  ></b-form-input>

                  <b-input-group-append>
                    <b-button
                      :disabled="!filter"
                      @click="filter = ''"
                      variant="danger"
                      >Clear</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
          </div>
          <b-table
            class="mt-3"
            striped
            hover
            :items="users"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            thead-class="bg-primary text-white"
            responsive
            @filtered="onFiltered"
          >
            <template #cell(role)="row">
              <b-badge
                class="mr-1 m-1"
                variant="info"
                v-for="(role, index) in row.item.role"
                :key="index"
              >
                {{ role.name }}
              </b-badge>
            </template>
            <template #cell(actions)="row">
              <b-dropdown
                id="dropdown-dropleft"
                no-caret
                dropleft
                text="Drop-top"
                variant="primary"
                class="m-2"
              >
                <template #button-content>
                  <i class="fas fa-ellipsis-v"></i>
                </template>

                <div>
                  <b-dropdown-item @click="assignOrkoCenter(row.item)">
                    <span class="pr-4"><i class="fas fa-university"></i></span>
                    Assign Orko Center
                  </b-dropdown-item>
                  <b-dropdown-item
                    href="#"
                    @click="changePassword(row.item)"
                    class="d-flex align-item-center"
                    ><span class="pr-4"><i class="fas fa-lock-open"></i></span
                    >Change password
                  </b-dropdown-item>
                  <b-dropdown-item
                    href="#"
                    @click="userDetails(row.item.id)"
                    class="d-flex align-item-center"
                    ><span class="pr-4"><i class="fas fa-eye"></i></span>View
                  </b-dropdown-item>
                </div>
              </b-dropdown>
            </template>
          </b-table>
          <div v-if="users.length > 20">
            <b-pagination
              v-model="currentPage"
              :total-rows="users.length"
              :per-page="perPage"
              size="lg"
              class="my-0"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="resetPassword" size="lg" title="Reset Password" hide-footer>
      <b-form @submit="resetPassword" @reset="onReset">
        <b-form-group
          id="input-group-1"
          label="Password * "
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="reset.password"
            type="password"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Action comment">
          <b-form-textarea
            id="textarea"
            class="mb-2"
            v-model="reset.comment"
            placeholder="Enter something..."
            rows="3"
          ></b-form-textarea>
          <span class="text-danger"
            ><b>Note: </b>Provide a valid reason/comment here. No dummy or
            irrelevant content will be acceptable</span
          >
        </b-form-group>

        <b-form-group>
          <div>
            <p>N.B: Password should be unique.</p>
            <p>1. Do not use repeated password.</p>
            <p>2. Please set at least 6 character length password.</p>
            <p>3. Do not use same password for multiple users.</p>
          </div>
        </b-form-group>

        <b-button type="submit" variant="primary" class="mr-5">Submit</b-button>
        <b-button type="reset" variant="danger">Reset</b-button>
      </b-form>
    </b-modal>
    <vue-snotify></vue-snotify>
    <AssignUserToInstitute
      :doctor_id="user_id"
      v-if="assign_user_to_institute_modal"
      @modalClosed="assign_user_to_institute_modal = false"
    />
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { ResponseMixin } from "../../mixins/core/ResponseMixin";
import AssignUserToInstitute from "../modal/AssignUserToInstitute";

export default {
  name: "Cashier",
  mixins: [validationMixin, ResponseMixin],
  title: "Cashier",
  components: {
    AssignUserToInstitute,
  },
  data() {
    return {
      breadcrumbs: "cashiers",
      form: {
        name: "",
        email: "",
        password: "",
        phone_number: "",
      },
      reset: {
        password: "",
        comment: "",
      },
      loading: true,
      currentPage: 1,
      perPage: 20,
      pageOptions: [20, 40, 60, { value: 100, text: "Show a lot" }],
      localActive: false,
      nameState: null,
      emailState: null,
      passwordState: null,
      phone_numberState: null,
      users: [],
      fields: [
        {
          key: "id",
          sortable: true,
        },
        {
          key: "name",
          sortable: true,
        },
        {
          key: "email",
          sortable: true,
        },
        {
          key: "phone_number",
          sortable: true,
        },
        {
          label: "Role",
          key: "role",
        },
        {
          key: "user_type",
          sortable: true,
        },
        {
          key: "confirmation",
          sortable: true,
        },
        {
          key: "otp",
          sortable: true,
        },
        {
          key: "actions",
        },
      ],
      filter: null,
      filterOn: [],
      user_id: "",
      assign_user_to_institute_modal: false,
    };
  },
  validations: {
    form: {
      name: {
        required,
      },
      email: {
        required,
      },
      phone_number: {
        required,
      },
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Users", route: "users" },
      { title: "Cashiers" },
    ]);
  },
  created() {
    localStorage.setItem("tabIndex", 1);
    this.$root.$emit("call-active-menu", 1);
    this.getUser();
  },
  methods: {
    assignOrkoCenter(item) {
      this.user_id = item.id;
      this.assign_user_to_institute_modal = true;
    },
    getUser() {
      axios
        .get(
          `api/v1/user/list?user_type=cashier&limit=-1&platform=backoffice&api_token=${localStorage.getItem(
            "api_token"
          )}`
        )
        .then((response) => {
          if (response.data.status_code == "500") {
            this.$router.push("/access-denied");
          } else {
            this.users = response.data.data;
            this.loading = false;
          }
        });
    },

    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.nameState = valid;
      this.emailState = valid;
      this.passwordState = valid;
      this.phone_numberState = valid;
      return valid;
    },
    resetModal() {
      this.form = {};
      this.nameState = null;
      this.emailState = null;
      this.passwordState = null;
      this.phone_numberState = null;
    },
    handleSave(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }

      let form = new FormData();
      form.append("fullname", this.form.name);
      form.append("email", this.form.email);
      form.append("password", this.form.password);
      form.append("user_type", "cashier");
      form.append("phone_number", this.form.phone_number);
      form.append("platform", "backoffice");
      form.append("is_system_user", "1");
      form.append("sub_type", "backoffice");
      form.append("system_user", localStorage.getItem("user_id"));

      this.$nextTick(() => {
        axios
          .post(`${process.env.VUE_APP_URL}/api/v1/register`, form)
          .then((response) => {
            this.checkResponse(
              response.data.message,
              response.data.status_code
            );
            this.getUser();
            this.form = "";
            this.$bvModal.hide("modal");
          });
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    userDetails(id) {
      this.$router.push(`/user/details/${id}`);
    },
    changePassword(row) {
      this.user_id = row.id;
      this.$bvModal.show("resetPassword");
    },
    resetPassword(event) {
      event.preventDefault();
      let form = new FormData();
      form.append("password", this.reset.password);
      form.append("comment", this.reset.comment);
      form.append("action_type", "general");
      form.append("module_type", "user");
      form.append("user_id", this.user_id);
      if (!this.user_id) {
        return alert("Sorry user id not found");
      }
      axios
        .post(
          `${
            process.env.VUE_APP_URL
          }/api/v1/user/password/update?api_token=${localStorage.getItem(
            "api_token"
          )}`,
          form
        )
        .then((response) => {
          if (response.data.status_code == 400) {
            return this.$snotify.error("Something worng");
          }
          this.$bvModal.hide("resetPassword");
          this.form = "";
          this.$snotify.success("Password successfully reset.");
          // this.getUser();
        });
    },
    onReset(event) {
      event.preventDefault();
      this.reset = {};
    },
  },
  computed: {
    rows() {
      return this.users.length;
    },
  },
  currentPage: {
    handler: function(value) {
      this.getUser().catch((error) => {
        console.error(error);
        console.error(value);
      });
    },
  },
};
</script>

<style scoped>
/*.d-flex {*/
/*  align-items: center;*/
/*}*/
</style>
